// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-tsx": () => import("./../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-demos-image-classification-tsx": () => import("./../../src/pages/demos/image_classification.tsx" /* webpackChunkName: "component---src-pages-demos-image-classification-tsx" */),
  "component---src-pages-demos-image-object-detection-aerial-tsx": () => import("./../../src/pages/demos/image_object_detection_aerial.tsx" /* webpackChunkName: "component---src-pages-demos-image-object-detection-aerial-tsx" */),
  "component---src-pages-demos-image-object-detection-tsx": () => import("./../../src/pages/demos/image_object_detection.tsx" /* webpackChunkName: "component---src-pages-demos-image-object-detection-tsx" */),
  "component---src-pages-demos-tsx": () => import("./../../src/pages/demos.tsx" /* webpackChunkName: "component---src-pages-demos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-terms-js": () => import("./../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-blog-post-js": () => import("./../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tag-template-js": () => import("./../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

